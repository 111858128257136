/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

// import 'babel-polyfill'; // Include this when we find out we need it because of IE etc.

import '../main.css';


const softwareStyle = {
  display: 'block',
  fontSize: '.3em',
  position: 'absolute',
  right: 0,
  bottom: 0,
  color: 'rgba(255,255,255,0.6)',
  marginBottom: '-.5em',
  marginRight: '.1em',
};

const imgStyle = {
  verticalAlign: 'middle',
  display: 'inline-block',
};

const yankeeTextStyle = {
  position: 'relative',
  fontFamily: 'Oswald, sans-serif',
};

const HeadingDesktop = styled.div`
  @media (min-width: 768px) { 
    display: none;
  }
`;

const HeadingMobile = styled.div`
  display: none;

  @media (min-width: 768px) { 
    display: inherit;
  }
`;

const Layout = ({ children }) => (
  <div>
    <div style={{ textTransform: 'uppercase', backgroundColor: 'black', color: '#fff', borderBottom: '6px solid #d5392d', fontSize: 24, paddingBottom: 0 }}>
      <HeadingMobile
        style={{
          fontWeight: 400,
          position: 'relative',
          padding: '.5em 0',
          color: 'white',
          fontSize: '2.5em',
          whiteSpace: 'nowrap',
          width: '6.5em',
          margin: 'auto',
        }}
      >
        <img src="/images/yankee.png" style={{ ...imgStyle, marginBottom: '.3em', height: 64 }} alt="" />

        <span style={{ ...yankeeTextStyle, marginLeft: '.3em' }}>YANKEE
          <span style={softwareStyle}>software</span>
        </span>
      </HeadingMobile>
      <HeadingDesktop
        style={{
          fontWeight: 400,
          position: 'relative',
          padding: '.5em 0',
          color: 'white',
          fontSize: '1.5em',
          textAlign: 'center',
        }}
      >
        <div>
          <img src="/images/yankee.png" style={{ ...imgStyle, height: 32 }} alt="" />
        </div>

        <div style={{ marginTop: '-.4em' }}>
          <span style={{ ...yankeeTextStyle, fontSize: '0.8em' }}>YANKEE
            <span style={softwareStyle}>software</span>
          </span>
        </div>
      </HeadingDesktop>

      <ul id="menu">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/about">About</Link></li>
      </ul>
    </div>

    {children}

    <div style={{ paddingTop: '3em', paddingBottom: '3em', background: 'black' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ lineHeight: '200%', padding: '2em', color: '#ddd', borderRadius: '.5em', transition: 'all 0.3s ease-out' }} id="contact">
          <strong>Mikael Finstad</strong><br />
          +47 95 99 95 17<br />
          <a href="mailto:mikael@yankee.no">mikael@yankee.no</a><br />
        </div>
      </div>
    </div>

    <div style={{ backgroundColor: '#d5392d', color: 'white', padding: '2em', display: 'flex', justifyContent: 'center' }}>
      <div>
        <div style={{ color: 'white', textTransform: 'uppercase', textAlign: 'center' }}>
        Mikael Finstad - Calmeyers gate 4 - 0183 Oslo - Norway<br />
        </div>
      </div>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
